/**
 * Official Jspreadsheet plugins
 *
 * Website: https://jspreasheet.com
 * Description: Create amazing web based spreadsheets.
 */

if (! organogram && typeof(require) === 'function') {
    var organogram = require('@jsuites/organogram');
}

;(function (global, factory) {
    typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = factory() :
    typeof define === 'function' && define.amd ? define(factory) :
    global.orgchart = factory();
}(this, (function () {

    /**
     * Create a plugin object
     * @param {object} spreadsheet object.
     * @param {object} plugin options
     */
    return (function(spreadsheet) {
        // Plugin object
        let plugin = {};
        let orgContainer = null;
        let orgInstance = null;
        let roles = null;

        const open = function() {
            this.resetSelection();
            spreadsheet.worksheets[0].table.style.display = 'none';
            orgContainer.style.display = '';
        }

        const close = function() {
            spreadsheet.worksheets[0].table.style.display = '';
            orgContainer.style.display = 'none';
        }

        /**
         * Initial executions
         */
        plugin.init = function(obj) {
            if (! orgContainer && obj.options.role === 'organogram') {
                // Organogram container
                orgContainer = document.createElement('div');
                // Add to the correct worksheet container
                obj.content.appendChild(orgContainer);
                // Options
                let opt = {
                    width: 800,
                    height: 600,
                    data: obj.options.data,
                    vertical: false,
                }
                if (roles) {
                    opt.roles = roles;
                }
                // Border
                orgContainer.style.borderTop = '1px solid #ccc';
                orgContainer.style.display = 'none';
                // Create organogram
                orgInstance = organogram(orgContainer, opt);
            } else {
                // Roles defined?
                if (obj.options.role === 'roles') {
                    roles = obj.options.data;

                    if (orgInstance) {
                        orgInstance.setRoles(roles);
                    }
                }
            }
        }

        /**
         * Jexcel events
         */
        plugin.onevent = function(method) {
            if (orgInstance) {
                if (method === 'onafterchanges') {
                    orgInstance.refresh();
                } else if (method === 'onbeforeselection') {
                    close();
                }
            }
        }

        /**
         * Run on toolbar
         * @param toolbar toolbar instance
         */
        plugin.toolbar = function(toolbar) {
            let worksheet = spreadsheet.worksheets[0];
            toolbar.items.push({
                content: 'group',
                onclick: function(a,b,c) {
                    spreadsheet.openWorksheet(0);

                    if (c.children[0].innerText === 'group') {
                        open.call(worksheet);
                        c.children[0].innerText = 'group_off';
                    } else {
                        close.call(worksheet);
                        c.children[0].innerText = 'group';
                    }
                },
                tooltip: 'Toggle organogram',
                updateState: function(a,b,c) {
                    if (orgContainer) {
                        if (orgContainer.offsetHeight) {
                            c.children[0].innerText = 'group_off';
                        } else {
                            c.children[0].innerText = 'group';
                        }
                    }
                }
            })
            // Can be used to overwrite the toolbar
            return toolbar;
        }

        // Return the object
        return plugin;
    });

})));